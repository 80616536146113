import ContentsTitle from "src/Components/Atoms/Title/contentsTitle";
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav";
import { MaruichiTemplate } from "src/Components/Templates/maruichiTemplate";
import styled from "styled-components";


export const DownloadPage = () => {
    document.title = '申込用紙ダウンロード | 丸一不動産 宮崎県日向市の総合不動産会社'
    const time = Date.now()
    return (
       <MaruichiTemplate>
            <div className="contentsHeader--inner">
                <BreadcrumbNav 
                    items={[
                        { name:`申込用紙ダウンロード`, href:'', active:true }
                    ]}
                ></BreadcrumbNav>
                <ContentsTitle>申込用紙ダウンロード</ContentsTitle>
            </div>  
            <SComapny className="company--inner">
                <dl>
                    <dt>入居申込書</dt>
                    <dd>
                    賃貸入居申し込みの方は、「入居申込書（PDF）」を印刷して、必要事項位を記入し、FAXでお送りください。<br/>
                    <a href={`https://maru-fudosan.com/PDF/nyukyomoushikomi.pdf?${time}`} target="_blank">入居申込書（PDF）</a>
                    <a href={`https://maru-fudosan.com/PDF/nyukyomoushikomi_houjin.pdf?${time}`} target="_blank">入居申込書（法人様向け）（PDF）</a>

                    <strong>FAX：0982-53-4908</strong>
                    </dd>
                    
                    <dt>その他</dt>
                    <dd>
                    <p><a href={`https://maru-fudosan.com/PDF/kounyumoushikomi.pdf?${time}`} target="_blank">不動産購入申込（PDF）</a></p>
                    <p><a href={`https://maru-fudosan.com//PDF/inintsuchi.pdf?${time}`} target="_blank">委任通知書（PDF）</a></p>
                    </dd>
                   
                </dl>
              
            </SComapny>
       </MaruichiTemplate>

    )
}

const SComapny = styled.div`
    margin-bottom: 120px;
    a{
        font-size: 1.8rem;
        display: block;
        padding: 10px 0;
        color: ${props => props.theme.colors.blue};
        text-decoration: underline;
        font-weight: bold;
        &::before{
            content: '・';
        }
        &:hover{
            color: ${props => props.theme.colors.red};
        }
    }
    strong{
        font-size: 1.8rem;
    }
    dl{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        dt,dd{
            box-sizing: border-box;
            padding: 10px;
            line-height: 3.0rem;
        }
        dt{
            flex-basis: 20%;
            font-weight: bold;
        }
        dd{
            flex-basis: 80%;
        }
    }
    .map_photo{
        display: flex;
        > div{
            flex-basis: 48%;
        }
        .map{
            position: relative;
            width: 100%;
            padding-bottom: 20%;
            iframe{
                position: absolute;
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
    &.company--inner{
        max-width: 980px;
    }
`

