import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav"
import { SlideGallary } from "src/Components/Molecules/SlideGallary"
import { BukkenTemplate } from "src/Components/Templates/bukkenTemplate"
import { BaibaiInfo } from "src/Types/bukken.d"
import { textToBr } from "src/utils/textUtils"
import parse from 'html-react-parser';
import { BaibaiColumTable,  } from "src/Components/Molecules/ColunmTable"
import { Gmap } from "src/Components/Atoms/Gmap"
import { BukkenForm } from "../BukkenForm"
import { formattedYearMonth } from "src/utils/dateTime"



export const  BaibaiBukkenView = ({bukken}:{bukken: BaibaiInfo}) => {

    
    return (
       <>
        <div className="contentsHeader--inner">
            <BreadcrumbNav
                items={[
                    { name:`中古住宅物件一覧`, href:'/baibais', active:false },
                    { name: `${bukken.name}`, href:'/baibais', active:true }
                ]}
            ></BreadcrumbNav>
            <ContentsTitle>{bukken.name}</ContentsTitle>
            </div>
            <section className="bukken-view">
            <div className="bukken-view--inner">
                <div className="bukkenHeader">
                    <div className="price">
                        <label>価格</label>
                        {bukken.price}
                    </div>
                    <div className="layout">
                        <label>建物面積</label>
                        {bukken.building_area}
                    </div>
                    <div className="layout">
                        <label>土地面積</label>
                        {bukken.land_area}
                    </div>
                    
                    <div className="other">
                        <span>住所：{bukken.address}</span><br/>
                        <span>小学校区：{bukken.search_params.jhs}</span><br/>
                        <span>築年月：{bukken.built_year_month ? formattedYearMonth(bukken.built_year_month) : '-'}</span><br/>
                    </div>
                    <div className="memo">
                        <h4>物件概要</h4>
                        <p>
                            { parse(textToBr(bukken.summary)) }
                        </p>
                    </div>
                </div>
                <div className="bukken-images">
                    <div>
                        <SlideGallary images={bukken.images} />
                    </div>
                    <div>
                        <SlideGallary images={bukken.madoris} />
                    </div>
                </div>
                {bukken.gmap &&
                <Gmap iframe={bukken.gmap}></Gmap>
                }
                <div className="bukken-info">
                    <BaibaiColumTable detail={bukken}/>
                </div>
            </div>
        </section>
       </>   
    )
}