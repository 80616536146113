import { useEffect, useState } from "react"
import ContentsTitle from "src/Components/Atoms/Title/contentsTitle"
import BreadcrumbNav from "src/Components/Molecules/Nav/breadcrumbNav"
import { SlideGallary } from "src/Components/Molecules/SlideGallary"
import { BukkenTemplate } from "src/Components/Templates/bukkenTemplate"
import { fetchChintaiInfo } from "src/Data/fetchChintai"
import { BaibaiInfo, ChintaiInfo, LandInfo } from "src/Types/bukken.d"
import { formatCurrency, numFormat, textToBr } from "src/utils/textUtils"
import parse from 'html-react-parser';
import { BaibaiColumTable, ChintaiColumTable, LandColumTable } from "src/Components/Molecules/ColunmTable"
import { Gmap } from "src/Components/Atoms/Gmap"
import { BukkenForm } from "../BukkenForm"
import { formattedYearMonth } from "src/utils/dateTime"
import { fetchLandInfo } from "src/Data/fetchLand"



export const  LandBukkenView = ({bukken}:{bukken: LandInfo}) => {


    return (
       <>
       <div className="contentsHeader--inner">
            <BreadcrumbNav
                items={[
                    { name:`土地物件一覧`, href:'/lands', active:false },
                    { name: `${bukken.name}`, href:'/lands', active:true }
                ]}
            ></BreadcrumbNav>
            <ContentsTitle>{bukken.name}</ContentsTitle>
            </div>
            <section className="bukken-view">
            <div className="bukken-view--inner">
                <div className="bukkenHeader">
                    <div className="price">
                        <label>価格</label>
                        {bukken.price}
                    </div>
                    <div className="layout">
                        <label>土地面積</label>
                        {bukken.land_area}
                    </div>
                    
                    <div className="other">
                        <span>住所：{bukken.address}</span><br/>
                        <span>小学校区：{bukken.search_params.jhs}</span><br/>
                    </div>
                    <div className="memo">
                        <h4>物件概要</h4>
                        <p>
                            { parse(textToBr(bukken.summary)) }
                        </p>
                    </div>
                </div>
                <div className="bukken-images">
                    <div>
                        <SlideGallary images={bukken.images} />
                    </div>
                    <div>
                        
                    </div>
                </div>

                {bukken.gmap &&
                <Gmap iframe={bukken.gmap}></Gmap>
                }
                
                <div className="bukken-info">
                    <LandColumTable detail={bukken}/>
                </div>
            </div>
        </section>
       </>   
    )
}